<template>
  <div class="phone-page">
    <div class="phone-left">
      <el-form id="form-box">
        <el-form-item>
          <el-input
            v-model="value"
            size="small"
            placeholder="请输入昵称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            size="small"
            type="primary"
            style="width: 100%; border-radius: 16px"
            @click="setTask"
            :loading="btnLoading"
          >
            查询
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-checkbox-group v-model="checkedType">
            <div v-for="(item, i) in interfaceData" :key="i">
              <el-checkbox :label="item.name">
                <span>{{ item.display_name }}</span>
                <span class="el-icon-coin" style="margin-left: 10px">
                  {{ item.price }}
                </span>
              </el-checkbox>
            </div>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div class="left-log" :style="{ height: logComHeight + 'px' }">
        <logCom
          :logList="logList"
          :title="'近十条昵称查询记录'"
          @selectLog="selectLog"
        />
      </div>
    </div>
    <div class="phone-right">
      <div v-if="loading" class="right-title">
        正在搜索
        <span>{{ value || selectValue }}</span>
        的相关数据
      </div>
      <div v-else class="right-title">查询</div>
      <div class="right-content">
        <!-- 实时数据 -->
        <div>
          <div class="right-table-title">
            <img src="@/assets/imgs/i10.png" alt="" />
            <span class="s-1">实时数据</span>
            <span class="s-2" v-if="loading">
              共有
              <span class="s-2-1">{{ dataList.length }}</span>
              条
              <span v-if="socketLoading">，查询已完成</span>
              <span v-else>，查询进行中...</span>
            </span>
          </div>
          <div class="real-data-content" v-if="dataList.length > 0">
            <el-collapse
              accordion
              class="el-collapse-style"
              v-for="(item, i) in dataList"
              :key="i"
            >
              <el-collapse-item>
                <template slot="title">
                  <div class="collapse-title">
                    <img
                      :src="
                        item.website_info ? item.website_info.website_logo : ''
                      "
                      alt=""
                    />
                    <span class="s-1">
                      {{
                        item.website_info ? item.website_info.website_name : ''
                      }}|
                    </span>
                    <span class="s-2">
                      {{
                        item.website_info
                          ? item.website_info.website_category.join(',')
                          : ''
                      }}
                    </span>
                    <span class="s-3">地址：</span>
                    <a
                      :href="
                        item.website_info ? item.website_info.website_index : ''
                      "
                      target="_blank"
                    >
                      {{
                        item.website_info ? item.website_info.website_index : ''
                      }}
                    </a>
                  </div>
                </template>
                <div class="collapse-content">
                  <div class="collapse-content-child" v-if="item.user_info">
                    <div
                      class="collapse-item"
                      v-for="(ele, j) in item.user_info"
                      :key="j"
                    >
                      <div class="item-top">
                        <div class="top-1">
                          <img :src="ele.avatar_url" alt="" />
                        </div>
                        <div class="top-2">
                          <div v-if="ele.nickname">
                            <label>昵称：</label>
                            <span>{{ ele.nickname }}</span>
                          </div>
                          <div v-if="ele.address">
                            <label>地址：</label>
                            <span>{{ ele.address }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="item-info">
                        <div v-if="ele.website_category">
                          <div v-if="ele.website_category.length > 0">
                            <label>网站分类：</label>
                            <div class="info-text">
                              {{ ele.join(',') }}
                            </div>
                          </div>
                        </div>

                        <div v-for="(val, key) in displayNameObj" :key="key">
                          <div v-if="ele[key]">
                            <label>{{ val }}：</label>
                            <div class="info-text">
                              {{ ele[key] }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
          <NoData v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tableComponent from '../components/TableCom.vue'
import logCom from '../components/LogCom.vue'
import NoData from '../../components/pagination/NoData.vue'
import { logList } from '@/api/history'
import { addTask } from '@/api/global'
import { io } from 'socket.io-client'
import { displayName } from '@/api/displayNickName'
export default {
  components: {
    tableComponent,
    logCom,
    NoData,
  },
  data() {
    return {
      //
      displayNameObj: displayName,
      dataList: [],
      checkedType: [],
      logList: [], //查询记录
      interfaceData: [],
      logComHeight: 0,
      value: '',
      loading: false,
      socketLoading: false,
      timer: null,
      selectValue: '',
      btnLoading: false,
    }
  },
  computed: {
    windowsHeight() {
      return this.$store.state.windowData.height
    },
    interfaceList() {
      if (this.$store.state.user.interface) {
        return this.$store.state.user.interface
      } else {
        return []
      }
    },
    storeToken() {
      return this.$store.state.user.token
    },
  },
  created() {
    this.pageType()
    this.getlogList()
  },
  mounted() {
    let height = document.getElementById('form-box').offsetHeight
    if (typeof height == 'number') {
      this.logComHeight = this.windowsHeight - height - 170
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer)
    this.websocketObj && this.websocketObj.disconnect()
  },
  methods: {
    pageType() {
      let path = this.$route.path
      if (path == '/phone/page') {
        this.routerPath = 'phone'
        this.routerName = '手机号'
      } else if (path == '/email/page') {
        this.routerPath = 'email'
        this.routerName = '邮箱'
      } else if (path == '/user/name') {
        this.routerPath = 'uname'
        this.routerName = '用户名'
      } else if (path == '/nick/name') {
        this.routerPath = 'nickname'
        this.routerName = '昵称'
      }

      this.interfaceData = this.interfaceList['nickname']
      this.checkedType = this.interfaceList['nickname'].map(item => {
        return item.name
      })
    },
    // 历史记录

    getlogList() {
      let obj = {
        page_index: 1,
        page_size: 10,

        target_type: this.routerPath,
      }
      logList(obj).then(res => {
        this.logList = res.data.data.task_list
      })
    },
    //添加任务 并获取id
    setTask() {
      if (this.socketLoading) {
        this.dataList = []
      }
      if (this.value.trim() == '') {
        this.$message({
          type: 'warning',
          message: `请输入昵称！`,
        })
        return
      }

      if (this.checkedType.length == 0) {
        this.$message({
          type: 'warning',
          message: `请选择查询模块！`,
        })
        return
      }
      this.loading = true
      this.btnLoading = true
      this.checkedTypeInclude = [].concat(this.checkedType)
      let obj = {
        target_account: this.value,
        target_type: this.routerPath,
        interface: this.checkedType,
      }

      addTask(obj)
        .then(res => {
          if (res.status == 200) {
            if (res.data && res.data.data) {
              this.search_uid = res.data.data.search_uid

              this.getlogList()
              this.$message({
                type: 'success',
                message: '任务提交成功!正在获取数据！',
              })
              this.initWebSocket()
            } else {
              this.loading = false
              this.checkedTypeInclude = []
              this.$message({
                type: 'error',
                message: res.data.message,
              })
            }
          }
          this.btnLoading = false
        })
        .catch(() => {
          this.loading = false
          this.btnLoading = false
          this.$message({
            type: 'error',
            message: `查询失败！`,
          })
        })
    },
    selectLog(row) {
      this.search_uid = row.search_uid
      this.dataList = []
      this.loading = true
      this.selectValue = row.target_account
      this.initWebSocket()
    },
    initWebSocket() {
      //初始化weosocket
      this.socketLoading = false
      this.websocketObj = io(process.env.VUE_APP_SOCKETIO_BASE, {
        auth: {
          token: this.storeToken,
          search_uid: this.search_uid,
        },
        path: process.env.VUE_APP_SOCKETIO_PATH,
        transports: ['websocket'],
      })
      this.websocketObj.on('json', res => {
        const { status, message, data } = res

        if (status === 'success' && message === '获取成功') {
          this.dataList = this.dataList.concat(data.network_footprint)
        }

        if (status === 'success' && message === '全部获取完成') {
          this.socketLoading = true
        }

        if (status === 'success' && message === '获取进行中的数据') {
          this.timer = setTimeout(
            () =>
              this.websocketObj.emit(
                'get_result',
                JSON.stringify({ next: data })
              ),
            3000
          )
        }
      })

      this.websocketObj.on('message', res => {
        console.log('接收到的message')
        console.log(res)
      })
      this.websocketObj.emit(
        'get_result',
        JSON.stringify({
          next: null,
        })
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.phone-page {
  width: 100%;
  height: calc(100vh - 79px);
  background: rgb(240, 242, 245);
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  .phone-left {
    width: 259px;
    height: 100%;
    background: #ffffff;
    border: 0px solid #000000;
    box-shadow: 0px 0px 10px 0px rgba(124, 124, 124, 0.2);
    border-radius: 5px;
    padding: 10px;
    overflow: hidden;
    ::v-deep .el-form-item {
      margin-bottom: 10px;
    }
    .left-log {
      width: 100%;
      height: calc(100% - 175px);
      overflow: hidden;
      margin-top: 30px;
    }
  }
  .phone-right {
    width: calc(100% - 270px);
    height: 100%;

    .right-title {
      width: 100%;
      font-size: 16px;
      font-weight: 300;
      color: #1a1a1b;
      line-height: 40px;
      border-bottom: 1px solid #cbc8da;
      white-space: nowrap;

      span {
        font-size: 22px;
        font-family: Constantia;
        font-weight: 400;
        color: #409eff;
        line-height: 40px;
      }
    }
    .right-content {
      height: calc(100% - 55px);
      width: 100%;
      overflow: hidden;
      overflow-y: auto;
      margin-top: 10px;
      position: relative;
      background: white;
      .right-table-title {
        width: 100%;
        height: 48px;
        background: white;
        box-shadow: 0px 2px 7px #d8d9d9;
        margin-bottom: 7px;
        line-height: 48px;
        white-space: nowrap;

        img {
          width: 32px;
          height: 32px;
          vertical-align: middle;
          margin-left: 15px;
        }
        .s-1 {
          font-size: 16px;
          margin-left: 15px;
          font-weight: 400;
          color: #355383;
        }
        .s-2 {
          font-size: 12px;
          margin-left: 40px;
          font-weight: 400;
          color: #355383;
          .s-2-1 {
            font-size: 20px;

            font-weight: 600;
            color: #409eff;
          }
        }
      }

      .real-data-content {
        padding: 15px 15px 5px 15px;
        width: 100%;
        overflow: hidden;
        background: #ffffff;
      }
      // 折叠版样式
      .collapse-title {
        img {
          width: 46px;
          height: 46px;
          object-fit: cover;
          vertical-align: middle;
        }
        .s-1 {
          font-size: 16px;
          font-weight: 400;
          color: #1a1a1b;
          margin-left: 13px;
          vertical-align: middle;
        }
        .s-2 {
          font-size: 16px;
          font-weight: 400;
          color: #02a9bd;
          vertical-align: middle;
        }
        .s-3 {
          margin-left: 25px;
          font-size: 12px;
          font-weight: bold;
          color: #050505;
          vertical-align: middle;
        }
        a {
          font-size: 16px;
          font-weight: 400;
          color: #0079d3;
          vertical-align: middle;
        }
      }
      .collapse-content {
        background: #e9e9e9;
        padding: 20px 10px;
        width: 100%;
        overflow: hidden;
        .collapse-content-child {
          width: calc(100% + 15px);
          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;
          .collapse-item {
            margin-right: 15px;
            margin-bottom: 10px;
            padding: 15px;
            width: calc(100% / 3 - 15px);
            // height: 210px;
            background: #ffffff;
            box-shadow: 0px 1px 6px 0px rgba(105, 105, 105, 0.35);
            border-radius: 5px;
            overflow: hidden;
            .item-top {
              display: flex;
              .top-1 {
                align-self: center;
                img {
                  width: 60px;
                  height: 60px;
                  vertical-align: middle;
                  border-radius: 50%;
                }
              }
              .top-2 {
                align-self: center;
                margin-left: 20px;
                // white-space: nowrap;
                label {
                  font-size: 14px;
                  font-weight: 300;
                  color: #2a2a2a;
                  line-height: 24px;
                }
                span {
                  font-size: 14px;
                  font-weight: 300;
                  color: #ff0000;
                  line-height: 24px;
                }
              }
            }
            .item-info {
              padding-top: 15px;
              > div {
                width: 100%;
                > div {
                  width: 100%;
                  display: flex;
                }
              }
              label {
                white-space: nowrap;
                width: 80px;
                font-size: 14px;
                font-weight: 300;
                color: #2a2a2a;
                line-height: 24px;
                margin-left: 5px;
              }
              .info-text {
                // height: 50px;
                width: calc(100% - 90px);
                word-wrap: break-word;
                font-size: 14px;
                font-weight: 300;
                color: #ff0000;
              }
            }
          }
        }
      }
    }
  }
}
::v-deep .el-collapse-item__header {
  background: #e9e9e9;
  border-bottom: 1px solid #dddddd;
}
.el-collapse-style {
  margin-bottom: 10px;
}
</style>
